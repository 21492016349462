import React from "react";

const Logo = () => {
  return (
    <svg
      width="174"
      height="36"
      viewBox="0 0 174 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="Logo"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 36C0.895431 36 -3.91405e-08 35.1046 -8.74228e-08 34L-6.11959e-07 22C-6.60242e-07 20.8954 0.89543 20 2 20L10 20L10 24C10 25.1046 10.8954 26 12 26L16 26L16 34C16 35.1046 15.1046 36 14 36L2 36ZM20 26L20 34C20 35.1046 20.8954 36 22 36L34 36C35.1046 36 36 35.1046 36 34L36 22C36 20.8954 35.1046 20 34 20L26 20L26 24C26 25.1046 25.1046 26 24 26L20 26ZM26 16L34 16C35.1046 16 36 15.1046 36 14L36 2C36 0.89543 35.1046 -1.53447e-06 34 -1.48619e-06L22 -9.61651e-07C20.8954 -9.13368e-07 20 0.895431 20 2L20 10L24 10C25.1046 10 26 10.8954 26 12L26 16ZM16 10L16 2C16 0.895431 15.1046 -6.60242e-07 14 -6.1196e-07L2 -8.74228e-08C0.89543 -3.91406e-08 -1.53447e-06 0.895432 -1.48619e-06 2L-9.61651e-07 14C-9.13368e-07 15.1046 0.895431 16 2 16L10 16L10 12C10 10.8954 10.8954 10 12 10L16 10Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 12H13.5H13.5H18C18 13 16 13.5 13.5 13.5C13.5 16 13 18 12 18V13.5V13H12V12.5C12 12.2239 12.2239 12 12.5 12H12.5Z"
        fill="currentColor"
      />
      <path
        d="M168.161 13.0762C171.663 13.0762 173.998 15.5605 173.998 19.332V28.551H169.239V20.3497C169.239 18.5837 168.191 17.3864 166.665 17.3864C164.779 17.3864 163.552 18.6735 163.552 21.5769V28.551H158.763V13.5252H163.552V14.9619C164.689 13.7646 166.276 13.0762 168.161 13.0762Z"
        fill="currentColor"
      />
      <path
        d="M155.283 28.551H150.344V8.49658H155.283V28.551Z"
        fill="currentColor"
      />
      <path
        d="M132.272 24.6299L136.942 23.6721C137.151 24.9592 138.318 25.4381 139.605 25.4381C140.863 25.4381 141.551 24.8993 141.551 24.2408C141.551 23.732 141.162 23.3429 140.054 23.1333L137.001 22.5347C134.218 22.0258 132.631 20.5293 132.631 18.1646C132.631 15.0816 135.295 13.0762 139.097 13.0762C142.838 13.0762 145.323 14.7823 145.921 17.3864L141.551 18.2544C141.401 17.3265 140.503 16.4884 139.037 16.4884C137.75 16.4884 137.331 17.117 137.331 17.6558C137.331 18.0748 137.51 18.4939 138.468 18.7034L142 19.4218C144.844 20.0204 146.161 21.7265 146.161 23.9415C146.161 27.2639 143.287 29 139.336 29C135.804 29 132.781 27.7129 132.272 24.6299Z"
        fill="currentColor"
      />
      <path
        d="M132.161 28.551H126.025L121.146 23.1333H120.278V28.551H115.489V7H120.278V18.6435H120.936L125.695 13.5252H131.472L124.887 20.6789L132.161 28.551Z"
        fill="currentColor"
      />
      <path
        d="M105.339 24.8095C106.866 24.8095 108.093 24.0014 108.602 22.1755L113.002 23.1333C112.254 26.6952 109.081 29 105.339 29C100.849 29 97.1379 25.7973 97.1379 21.0381C97.1379 16.3088 100.849 13.0762 105.339 13.0762C108.991 13.0762 112.164 15.3211 113.002 18.8531L108.512 19.9306C108.093 18.1048 106.866 17.2667 105.339 17.2667C103.184 17.2667 101.747 18.8531 101.747 21.0381C101.747 23.2531 103.184 24.8095 105.339 24.8095Z"
        fill="currentColor"
      />
      <path
        d="M87.4238 13.136C91.8836 13.136 95.655 16.3088 95.655 21.0381C95.655 25.7973 91.8836 28.9701 87.4238 28.9701C82.8741 28.9701 79.1027 25.7973 79.1027 21.0381C79.1027 16.3088 82.8741 13.136 87.4238 13.136ZM87.4238 24.9292C89.2795 24.9292 91.0455 23.4027 91.0455 21.0381C91.0455 18.7034 89.2795 17.1469 87.4238 17.1469C85.4782 17.1469 83.7122 18.7034 83.7122 21.0381C83.7122 23.4027 85.4782 24.9292 87.4238 24.9292Z"
        fill="currentColor"
      />
      <path d="M76.6074 28.551H71.8182V7H76.6074V28.551Z" fill="currentColor" />
      <path
        d="M65.1701 18.1946V18.3143C67.0259 18.4639 69.0313 19.9007 69.0313 22.9238C69.0313 26.2463 66.7864 28.551 62.4163 28.551H52V8.4966H62.3864C66.068 8.4966 68.4626 10.6816 68.4626 13.9741C68.4626 16.7279 66.517 18.0449 65.1701 18.1946ZM56.9388 12.4776V16.7878H61.249C62.7156 16.7878 63.6735 15.8898 63.6735 14.5429C63.6735 13.2857 62.6857 12.4776 61.4286 12.4776H56.9388ZM56.9388 24.5701H61.5483C63.1347 24.5701 64.0027 23.702 64.0027 22.3252C64.0027 21.0082 63.0449 20.1701 61.6381 20.1701H56.9388V24.5701Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Logo;
